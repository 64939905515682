import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'




const IncusPassesKDPC = ({ data }) => {
  return (
    <Layout pageTitle="INCUS Passes KDP-C">
      <h1>INCUS Passes KDP-C</h1>
      <h5>November 14, 2023</h5>

      <p>WASHINGTON, DC- INCUS Key Decision Point-C (KDP-C) was held at NASA HQ On November 14, 2023. Congratulations to the INCUS team for successfully passing KDP-C and moving the INCUS mission into <Link to="/timeline">Phase C</Link>. The move into Phase C confirms the INCUS mission and signals NASA's commitment to the project and its success. This transition from Phase B to C moves the project from the design stage to the construction stage of the instruments, observatories, and mission systems and brings us closer to launch. </p>

      <p>Upon passing KDP-C, INCUS Principal Investigator Sue van den Heever, University Distinguished Professor of Atmospheric Science at Colorado State University, said, “ I can confidently state that those attending the [KDP-C] meeting were impressed with the game-changing observations that INCUS will make, the unique approaches being used to obtain these observations, the innovative ways in which all of our technological needs and challenges are being successfully addressed, and the cohesiveness of the entire INCUS team. […] I am remarkably proud of our INCUS team and know that with such a talented team that the odds of implementing and conducting a successful mission are extremely high!”</p>

      <p>The purpose of Phase C is for the team to complete the design of the mission and its associated systems, build the hardware, code the software and firmware, and set up the data and algorithmic pipelines. Phase C is the final phase before the launch phase, Phase D, and represents a critical production phase for the mission.</p>



    </Layout >

  )
}

export default IncusPassesKDPC

